// import Hero from '@solid-ui-blocks/Hero/Block01'
import CookieConsent from "../blocks/CookieConsent";
import Divider from '@solid-ui-components/Divider'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import FeatureThree from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import FeatureTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import Footer from '../blocks/Footer/Block01'
import Hero from "../blocks/HeroVideoBg";
import Layout from '../@elegantstack/solid-ui-layout/src/Layout/Layout'
import ModalWithTabs from '../blocks/ModalWithTabs/Block01'
import OurServices from "../blocks/OurServices";
import Process from '@solid-ui-blocks/Features/Block03'
import React from 'react'
import Screenshot from '@solid-ui-blocks/FeaturesWithPhoto/Block03'
import Seo from '@solid-ui-components/Seo'
import styles from './_styles'
import theme from './_theme'
import {Container} from 'theme-ui'
import {graphql} from 'gatsby'
import {normalizeBlockContentNodes} from '@blocks-helpers'
import CustomHeader from "../blocks/CustomHeader/CustomHeader";
import Tabs from "../blocks/Tabs/Tabs";
import Arena from '../blocks/Arena/Arena'

const IndexPage = props => {
    const {allBlockContent, allFeedUad} = props.data;
    const content = normalizeBlockContentNodes(allBlockContent?.nodes);
    const feed = allFeedUad;

    return (
        <Layout theme={theme} {...props}>
            <Seo title='Inicio'/>
            {/* Modals */}
            <ModalWithTabs title='' content={content['contact']}/>
            {/* Blocks */}
            <CustomHeader title='' content={content['header-dark']}/>
            <Divider space='5'/>
            <Divider space='5'/>
            <Container title='' variant='full' sx={styles.heroContainerVideo}>
                <Hero title='' content={content['hero']} reverse/>
            </Container>
            <Divider space='5'/>
            <Divider space='5'/>
            <Divider space='5'/>
            <Container title='' variant='wide' sx={styles.tabsContainer}>
                <Tabs title='' space={5}>
                    <FeatureOne title='' content={content['tab-feature-one']}/>
                    <FeatureTwo title='' content={content['tab-feature-two']}/>
                    <FeatureThree title='' content={content['tab-feature-three']}/>
                </Tabs>
            </Container>
            <Divider space='5'/>
            <Divider space='5'/>
            <Divider space='5'/>
            <Container title='' variant='wide' sx={styles.tabsContainer}>
                <Tabs title='' space={3} variant='dots' position='bottom' arrows>
                    <Screenshot title='' content={content['screenshot-one']}/>
                    <Screenshot title='' content={content['screenshot-two']}/>
                    <Screenshot title='' content={content['screenshot-three']}/>
                </Tabs>
            </Container>
            <Divider space='5'/>
            <Divider space='5'/>
            <Divider space='5'/>
            <Process title='' content={content['process']}/>
            <Divider space='5'/>
            {/*<Container title=''variant='full' sx={styles.featuresContainer}>*/}
            {/*    <Features content={content['features']}/>*/}
            {/*</Container>*/}
            <Divider space='5'/>
            <Container title='' variant='full' sx={styles.ourServicesContainer}>
                <Divider space='5'/>
                <Divider space='5'/>
                <Container title='' variant='wide'>
                    <OurServices title='' content={content['our-services']}/>
                </Container>
                <Divider space='5'/>
                <Divider space='5'/>
            </Container>

            <Container title='' variant='full'>
                <Divider space='1'/>
                <Container title='' variant=''>
                    <Arena content={content['icex']}/>
                </Container>
                <Divider space='3'/>
            </Container>

            <Footer title='' content={content['footer']}/>
            <CookieConsent/>
        </Layout>
    )
}

export const query = graphql`
  query homepageSiteBlockContent {
    allBlockContent(filter: { page: { in: ["site/index", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    },
    allFeedUad(limit: 3) {
	    edges {
	      node {
	      	content {
	      		encoded
	      	}
	        id
	        link
	        title	        
	        categories
	        dc {
	            creator
	        }
	      }
	    }
	  }
  }
`
export default IndexPage
